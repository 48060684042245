import React from 'react';
import GridCard from "./GridCard";
import useEmblaCarousel from "embla-carousel-react";
import GridContainer from "@/components/common/GridContainer";
import {BREAKPOINTS} from "@/styles/themeSettings";
import styled from "styled-components";
import {useIsMobile} from "@/hooks/useDevice";
import {getMapKey} from "@/utils/utils";
import {ACTIVE_STATE} from "@/components/common/Cards";

const Wrapper = styled.div`
    display: flex;
    overflow: hidden;
	&.all-news {
		overflow: initial;
	}
`

const Content = styled(GridContainer)`
    width: 100%;
    row-gap: var(--grid-gap);
	&.all-news {
		display: grid!important;
		grid-template-columns: 1fr!important;
		row-gap: 56px;
	}
    @media (max-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET] - 1}px) {
        display: flex !important;
        .card {
            //flex: 0 0 100%; full size card
            min-width: 0;

            //make card colsize - 1, so next one is sticking out to indicate module is scrollable
            flex: 0 0 calc(((var(--size)) / var(--grid-count)) * 100%);
            margin-right: calc((1 / (var(--grid-count) * 2)) * 100%); //spacing;;
        }
    }
`

function sortCardsByIsActiveState(arr) {
	return arr.sort((a, b) => a?.active === b?.active ? 0 : a?.active === ACTIVE_STATE.YES ? -1 : 1);
}

const GridCards = ({
	data: passedData = [],
	color = 'black',
	animation = false,
	date = false,
	smallCover = false,
	paginate = false,
	horizontalCards = false,
	isAllNewsPage = false,
}) => {
	const data = passedData || []
	const isMobile = useIsMobile()
	const [WrapperRef] = useEmblaCarousel({
		loop: true,
		align: 'start',
		// active: isMobile //slider only on mobile
		active: isMobile && !isAllNewsPage,
	})

	const sortedData = sortCardsByIsActiveState(data)

	return (
		<Wrapper ref={WrapperRef} className={(isAllNewsPage && isMobile) ? "all-news cards-wrapper grid" : "cards-wrapper grid"}>
			<Content className={(isAllNewsPage && isMobile) ? "all-news cards-content no-margin" : "cards-content no-margin"}>
				{sortedData.map((item, index) =>
					<GridCard
						key={getMapKey(item, index)}
						data={item}
						color={color}
						date={date}
						smallCover={smallCover}
						horizontalCards={horizontalCards}
						paginate={paginate}
					/>
				)}
			</Content>
		</Wrapper>
	);
};

export default GridCards;
