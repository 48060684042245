import React, {useCallback, useMemo, useRef, useState} from 'react';
import Image, {formatImageCMSData} from "@/components/common/Image";
import {getCssSizeValue, isStringEmpty} from "@/utils/utils";
import styled, {useTheme} from "styled-components";
import {BREAKPOINTS, colors} from "@/styles/themeSettings";
import GridContainer from "@/components/common/GridContainer";
import Text from "@/components/common/Text";
import {useRouter} from "next/router";
import {getCardData} from "@/components/common/Cards";
import Reveal, {REVEAL_CLASSNAME} from "@/animations/Reveal";
import {useIsDesktop} from "@/hooks/useDevice";
import Container from "@/components/common/Container";
import CommonLineBreak from "@/components/common/LineBreak";
import SimpleIconButton, {SIMPLE_ICON_BUTTON_ANIMATIONS} from "@/components/common/SimpleIconButton";
import useGlobals from "@/hooks/useGlobals";


const ANIMATION_DURATION_MS = 300

const StyledOuterContainer = styled(Container)`
    width: 100%;
    position: relative;
    animation: fade-in 0.75s ease-in-out;
    cursor: pointer;
    border-top: ${({ $isFirst, theme}) => $isFirst ? '1px solid' + theme.colors.antiflashWhite : 'none'};
    border-bottom: none;
        //margin-top: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
    margin-top: 0;
    padding-top: ${getCssSizeValue(36, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding-top: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
        padding-bottom: ${getCssSizeValue(36, BREAKPOINTS.TABLET)};
            // border-bottom: 1px solid ${({theme}) => theme.colors.antiflashWhite};
    }
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        position: initial;
        margin-top: 0;
        padding-top: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)};
        padding-bottom: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};

        transition: border-top-color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out, padding-top ${ANIMATION_DURATION_MS}ms ease-in-out, padding-bottom ${ANIMATION_DURATION_MS}ms ease-in-out, background-color 0.3s ease-in-out;
        .list-img > .image {
            transition: transform 0.3s ease-in-out;
        }

        &:hover {
            background-color: ${({theme}) => theme.colors.antiflashWhite};

            .list-img > .image {
                transform: scale(1.1);
            }
        }
    }
`


const LineBreak = styled(CommonLineBreak)`
    display: none;
    background-color: ${({theme}) => theme.colors.antiflashWhite};

    &.active {
        background-color: ${({theme}) => theme.colors.black};

        &:after {
            transform: translateX(var(--paddingX)) scaleX(var(--target-scale, 1)) scaleY(var(--size)) !important;
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        display: block;
    }
`

const StyledCoverWrapper = styled(GridContainer)`
    position: relative;
`

const StyledReveal = styled(Reveal)`
    grid-column: 1/9;
    height: fit-content;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        grid-column: 5/9;
    }

    .${REVEAL_CLASSNAME}-child {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: var(--grid-gap);
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        grid-column: 5/16;

        .${REVEAL_CLASSNAME}-child {
            display: grid;
            grid-template-columns: repeat(11, 1fr);
            grid-gap: var(--grid-gap);
        }

        &.no-date-column {
            grid-column: 7/16;

            .${REVEAL_CLASSNAME}-child {
                display: grid;
                grid-template-columns: repeat(8 1fr);
                grid-gap: var(--grid-gap);
            }
        }
    }
`

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    //position: relative;

    .image {
        aspect-ratio: 4/3;
    }

    &.no-date-column {
        .image {
            aspect-ratio: 16/9;
        }
    }
`

const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    grid-column-start: 2;
    grid-column-end: 3;
`

const TitleAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

    grid-column-start: 1;
    grid-column-end: 5;

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        grid-column-start: 4;
        grid-column-end: 11;
        &.no-date-column {
            grid-column-start: 2;
            grid-column-end: 11;
            margin-top: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)};
        }
    }

    .date {
        margin-top: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
        margin-bottom: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            margin-top: unset;
            margin-bottom: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};
        }
    }

    p.title {
        margin-bottom: ${getCssSizeValue(12, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            margin-bottom: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            margin-bottom: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
        }
    }
`

const ArrowIconWrapper = styled.div`
    display: none;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;

        position: absolute;
        top: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};
        right: var(--paddingX);
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        top: 0;
        right: 0;
    }
`

const ListCard = ({data, animation = false, paginate = false, noDateColumn, isFirst = false}) => {
	const router = useRouter();
	const isDesktop = useIsDesktop()
	const ImageRef = useRef()
	const {
		id,
		url,
		cover,
		header,
		description,
		day,
		month
	} = useMemo(() => getCardData(data, router.locale), [data, router.locale])
	const theme = useTheme()
	const sizes = useMemo(() =>
		`100vw, (min-width: ${theme.breakpoints[BREAKPOINTS.TABLET]}px) 33vw, 
		(min-width: ${theme.breakpoints[BREAKPOINTS.DESKTOP]}px) 22vw`,
	[theme.breakpoints])
	const [hover, setHover] = useState(false)

	// useScaleFadeIn({
	// 	el: ImageRef,
	// 	onInView: true
	// })

	const handlePointerEnter = useCallback(() => {
		setHover(true)
	}, [])

	const handlePointerLeave = useCallback(() => {
		setHover(false)
	}, [])


	const handleClick = () => {
		if (paginate) {
			router.push({
				pathname: url,
				query: {page: router.query.page || 1}
			})
		} else {
			router.push({
				pathname: url,
			})
		}
	}

	const singleNewsCoverBackup = useGlobals(state => state.singleNewsCoverBackup);
	const finalCover = !isStringEmpty(cover.url) ? cover : formatImageCMSData(singleNewsCoverBackup || {})

	return (
		<div key={id} onClick={handleClick} className='embla__slide'>
			<StyledOuterContainer
				$isFirst={isFirst}
				className='no-margin'
				onPointerEnter={handlePointerEnter}
				onPointerLeave={handlePointerLeave}
			>
				<StyledCoverWrapper
					// $isFirst={$isFirst}
					className='no-margin'
					// onPointerEnter={onHoverEnterOrExit}
					// onPointerLeave={onHoverEnterOrExit}
				>
					<ImageWrapper
						className={noDateColumn ? "col-desktop-6 col-tablet-4 col-mobile-8 no-date-column list-img" : "col-desktop-4 col-tablet-4 col-mobile-8 list-img"}
					>
						<Image
							ref={ImageRef}
							src={finalCover.url}
							sizes={sizes}
							focalPoint={finalCover.focalPoint}
							placeholder={finalCover.placeholder}
							alt={finalCover.alt}
						/>
					</ImageWrapper>
					<StyledReveal className={noDateColumn ? 'no-date-column' : ''} onInView disabled={!animation}>
						{isDesktop && !noDateColumn && (
							<DateWrapper>
								<Text className="typo-borna typo-borna-15">
									{month}
								</Text>
								<Text className="typo-borna typo-borna-17">
									{day}
								</Text>
							</DateWrapper>
						)}
						<TitleAndDescriptionWrapper className={noDateColumn ? 'no-date-column' : ''}>
							{!isDesktop && !noDateColumn && (
								<div className='date typo-strawford typo-strawford-15'>
									<span>{day} {month}</span>
								</div>
							)}
							<Text className="title typo-borna typo-borna-18">{header}</Text>
							<Text as="div" className="typo-borna typo-borna-11" dangerouslySetInnerHTML={description}/>
						</TitleAndDescriptionWrapper>
					</StyledReveal>

					<ArrowIconWrapper className='arrow-icon'>
						<SimpleIconButton
							color={colors.chilliRed}
							rotation={135}
							animation={hover ? SIMPLE_ICON_BUTTON_ANIMATIONS.ROTATE_45 : null}
						/>
					</ArrowIconWrapper>
				</StyledCoverWrapper>

			</StyledOuterContainer>
			<LineBreak active={hover} target={ImageRef} size={6}/>
		</div>
	)
};

export default ListCard;
