import React, {forwardRef, useCallback, useMemo, useRef, useState} from 'react';
import styled, {useTheme} from "styled-components";
import Image, {formatImageCMSData} from "@/components/common/Image";
import Text from "@/components/common/Text";
import {getCssSizeValue, isStringEmpty} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import PreserveSearchParamsLink from "@/components/common/Links/PreserveSearchParamsLink";
import {ACTIVE_STATE, getCardData} from "@/components/common/Cards";
import {useRouter} from "next/router";
import useGlobals from "@/hooks/useGlobals";
import LineBreak from "@/components/common/LineBreak";
import NotActiveBadge from "@/components/common/NotActiveBadge";

const Wrapper = styled.div`
    animation: fade-in 0.75s ease-in-out;
	position: relative;
    --row-gap: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
    display: flex;
    flex-direction: column;
	width: 100%;
    row-gap: var(--row-gap);
    .image {
		aspect-ratio: 3/4;
		//&.smallCover {
		//	aspect-ratio: 1;
		//}
		&.horizontal {
			aspect-ratio: 4/3;
		}
    }
	
	p{
		word-break: break-word;
	}

    cursor: pointer;

    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) and (orientation: landscape){
        --row-gap: ${getCssSizeValue(12, BREAKPOINTS.DESKTOP)};
		&:hover {
			.break-line {
				// transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
				// height: 4px;
				// background-color: ${({ theme }) => theme.colors.chilliRed};
				// width: ${getCssSizeValue(89, BREAKPOINTS.MOBILE)};
				// @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
				// 	width: ${getCssSizeValue(89, BREAKPOINTS.TABLET)};
				// }
				// width: ${getCssSizeValue(89, BREAKPOINTS.DESKTOP)};
			}		
			.break-line-red {
				transition: height 0.3s ease-in-out, width 0.3s ease-in-out, opacity 0.1s ease-in-out, transform 0.3s ease-in-out;
				height: 3px;
				opacity: 1;
				transform: translateX(0);
				background-color: ${({ theme }) => theme.colors.chilliRed};
			}
			.card-date-bg {
				transition: background-color 0.3s ease-in-out;
				background-color: ${({theme}) => theme.colors.periwinkle};
			}
			.grid-card-image {
				transition: transform 0.3s ease-in-out;
				transform: scale(1.11);
			}
		}
    }
	//&.smallCover {
    //    cursor: default;
	//}
	
`

const ImageContainer = styled.div`
	position: relative;
	overflow: hidden;
	.grid-card-image {
		transition: transform 0.3s ease-in-out;
		transform: scale(1);
	}

	&.not-active {
		.image {
			filter: grayscale(100%);
			-webkit-filter: grayscale(100%);
		}
	}
`


const CardDate = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    transition: background-color 0.3s ease-in-out;
    background-color: ${({theme}) => theme.colors.white};
    width: ${getCssSizeValue(89, BREAKPOINTS.MOBILE)};
    height: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: ${getCssSizeValue(89, BREAKPOINTS.TABLET)};
        height: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
        width: ${getCssSizeValue(89, BREAKPOINTS.DESKTOP)};
        height: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
    }

    &.cl-white {
        background-color: ${({theme}) => theme.colors.richBlack};
    }
`

const MonthWrapper = styled.div`
	margin-bottom: ${getCssSizeValue(-10, BREAKPOINTS.DESKTOP)};
`

const Date = forwardRef(function Date({ enabled=false, color = 'black', month='', day='' }, fref){
	if(!enabled) return null
	return (
		<CardDate ref={fref} className={`cl-${color} card-date-bg`}>
			<MonthWrapper>
				<Text color={color} className="typo-strawford typo-strawford-15">{month}</Text>
			</MonthWrapper>
			<Text color={color} className="typo-borna typo-borna-17">{day}</Text>
		</CardDate>
	)
})

function checkIfTagIsLink(tag){
	switch (tag){
		case 'a':
			return true
		case PreserveSearchParamsLink:
			return true
		default:
			return false
	}
}
const GridCard = ({ data, date, color='black', tag=PreserveSearchParamsLink, paginate = false, horizontalCards= false }) => {
	const router = useRouter();
	const {
		id,
		url,
		cover,
		header,
		supportHeader,
		description,
		day,
		month,
		isActive
	} = useMemo(() => getCardData(data, router.locale), [data, router.locale])

	const DateRef = useRef()
	const SupportHeaderRef = useRef()
	const theme = useTheme()
	const [hover, setHover] = useState(false)
	const sizes = useMemo(() =>
		`100vw, (min-width: ${theme.breakpoints[BREAKPOINTS.TABLET]}px) 33vw, 
		(min-width: ${theme.breakpoints[BREAKPOINTS.DESKTOP]}px) 22vw`,
	[theme.breakpoints])
	const href = checkIfTagIsLink(tag) ? url : undefined

	const handlePointerEnter = useCallback(() => {
		setHover(true)
	},[])

	const handlePointerLeave = useCallback(() => {
		setHover(false)
	},[])

	const handleClick = () => {
		if (paginate) {
			router.push({
				pathname: href,
				query: { page: router.query.page || 1 }
			})
		} else {
			router.push({
				pathname: href,
			})
		}
	}

	const singleNewsCoverBackup = useGlobals(state => state.singleNewsCoverBackup);
	const finalCover = !isStringEmpty(cover.url) ? cover : formatImageCMSData(singleNewsCoverBackup || {})

	return (
		<Wrapper
			key={id}
			onClick={handleClick}
			onPointerEnter={handlePointerEnter}
			onPointerLeave={handlePointerLeave}
			// className={`card col-mobile-8 col-tablet-4 col-desktop-4 ${smallCover ? 'smallCover' : ''}`}
			className={`card col-mobile-7 col-tablet-4 col-desktop-4 ${horizontalCards ? 'horizontal' : ''}`}
		>
			<ImageContainer className={isActive === ACTIVE_STATE.NO ? 'not-active' : ''}>
				{isActive === ACTIVE_STATE.NO && (
					<NotActiveBadge />
				)}
				<Image
					// className={`${smallCover ? 'smallCover' : ''}`}
					className={`${horizontalCards ? 'horizontal grid-card-image' : 'grid-card-image'}`}
					src={finalCover.url}
					sizes={sizes}
					focalPoint={finalCover.focalPoint}
					placeholder={finalCover.blurUpThumb}
					alt={finalCover.alt}
				/>
				<Date
					ref={DateRef}
					enabled={date}
					color={color}
					day={day}
					month={month}
				/>
			</ImageContainer>
			{supportHeader !== '' && (
				<>
					<LineBreak
						active={hover}
						target={date ? DateRef : SupportHeaderRef}
						inverse={date}
					/>
					<Text ref={SupportHeaderRef} color={color} className="typo-strawford typo-strawford-12 uppercase square static support-header" >
						{supportHeader}
					</Text>
				</>
			)}
			<Text color={color} className="typo-borna typo-borna-87 header card-header">
				{header}
			</Text>
			<Text as="div" color={color} className="typo-strawford typo-strawford-88 description" dangerouslySetInnerHTML={description}/>
		</Wrapper>
	);
};

export default GridCard;
