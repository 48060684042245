import React, {useEffect} from 'react';
import ListCard from "@/components/common/Cards/List/ListCard";
import styled from "styled-components";
import {getCssSizeValue, getMapKey} from "@/utils/utils";
import useEmblaCarousel from 'embla-carousel-react'
import {BREAKPOINTS} from "@/styles/themeSettings";
import {useIsMobile} from "@/hooks/useDevice";
import SliderProgressIndicator from "@/components/common/SliderProgressIndicator";

const Wrapper = styled.div`
    overflow: hidden;
    margin-bottom: ${getCssSizeValue(56, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-bottom: ${getCssSizeValue(56, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin-bottom: ${getCssSizeValue(136, BREAKPOINTS.DESKTOP)};
        padding-bottom: 3px;
    }
    @media (max-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET] - 1}px) {
        .embla__container {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 100%;
            margin-bottom: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};

            @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
                margin-bottom: unset;
                animation: fade-in 0.75s ease-in-out;
                display: flex;
                flex-direction: column;
            }
        }
		
		.default-container {
			display: flex;
			flex-direction: column;
			gap: 20px;

            @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            	display: initial;
				gap: unset;
			}
		}

        .embla__slide {
            flex: 0 0 100%;
            min-width: 0;
        }
    }
`

const ListCards = ({data, animation = false, paginate = false, noDateColumn = false, mobileSliderEnabled = true}) => {
	const isMobile = useIsMobile()
	const [emblaRef, emblaApi] = useEmblaCarousel({
		active: isMobile && mobileSliderEnabled
	})

	useEffect(() => {
		if (!emblaApi) return
		emblaApi.reInit({active: isMobile && mobileSliderEnabled})
	}, [isMobile, emblaApi]);

	return (
		<Wrapper ref={emblaRef} className="cards-wrapper list">
			<div className={mobileSliderEnabled ? 'embla__container' : 'default-container'}>
				{data.map((item, index) =>
					<ListCard
						key={getMapKey(item, index)}
						data={item}
						animation={animation}
						paginate={paginate}
						noDateColumn={noDateColumn}
						isFirst={index === 0}
					/>
				)}
			</div>
			{(isMobile && mobileSliderEnabled) && (
				<SliderProgressIndicator api={emblaApi}/>
			)}
		</Wrapper>
	);
};

export default ListCards;
