import useSliderProgress from "@/components/common/SliderProgressIndicator/useSliderProgress";
import styled from "styled-components";
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: ${getCssSizeValue(4, BREAKPOINTS.MOBILE)};
`

const SinglePin = styled.div`
	--size: ${getCssSizeValue(6, BREAKPOINTS.MOBILE)};
	background: ${({ theme }) => theme.colors.platinum };
	height: var(--size);
	width: var(--size);

	transition: all .2s;
	
	&.is-active {
		background: red;
		width: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
	}
`

export default function SliderProgressIndicator({ api }) {
	const { selectedIndex, scrollSnaps, onDotButtonClick } = useSliderProgress(api)

	return <Wrapper>
		{scrollSnaps.map((item, index) => {
			const isActive = selectedIndex === index;
			return <SinglePin key={index} className={isActive ? "is-active" : ""} />
		})}
	</Wrapper>
}