import React from "react";
import useGlobals from "@/hooks/useGlobals";
import styled from "styled-components";
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
    z-index: 2;
	background: ${({ theme }) => theme.colors.periwinkle};
	color: ${({ theme }) => theme.colors.richBlack};
    padding: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)} ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
	
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding: ${getCssSizeValue(20, BREAKPOINTS.TABLET)} ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
	}

    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
        padding: ${getCssSizeValue(20)} ${getCssSizeValue(30)};
	}
`

export default function NotActiveBadge() {
	const notActiveActionText = useGlobals(state => state.notActiveActionText)

	return (
		<Wrapper className='typo-borna typo-borna-98'>
			{notActiveActionText}
		</Wrapper>
	)
}