import React from 'react';
import GridCards from "@/components/common/Cards/Grid";
import ListCards from "@/components/common/Cards/List";
import {getHtmlFromRedactorField, getUrlFunctionFromItem} from "@/utils/utils";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import {IMAGE_FALLBACK} from "@/constants";
import {formatImageCMSData} from "@/components/common/Image";

dayjs.extend(localizedFormat);
dayjs.extend(localeData);

export const CARDS_TYPE = {
	LIST: 'list',
	GRID: 'grid',
}

export const DEFAULT_CARD = {
	id: '',
	url: '',
	card: {
		header: '',
		description: '',
		supportHeader: '',
		cover: { ...IMAGE_FALLBACK }
	}
}

export function getSimpleCardData(data){
	const id = data?.id || ''
	const url = data?.url || ''
	const header = data?.card?.header || DEFAULT_CARD.card.header
	const cover = formatImageCMSData(data?.card?.cover || DEFAULT_CARD.card.cover)
	const description = getHtmlFromRedactorField(data?.card?.description || DEFAULT_CARD.card.description)

	return {
		id,
		url,
		cover,
		header,
		description,
	}
}

export const ACTIVE_STATE = {
	YES: 'yes',
	NO: 'no'
}

export function getCardData(data, locale='pl'){
	const { id, url, cover, header, description } = getSimpleCardData(data)
	const supportHeader = data?.card?.supportHeader || DEFAULT_CARD.card.supportHeader
	const createdAt = data?._createdAt || ''; //
	const date = createdAt && dayjs(createdAt) || null;
	const month = date?.locale(locale)?.format("MMM") || null;
	const day =  date?.locale(locale)?.format("DD") || null;
	const backgroundColor = data?.card?.backgroundColor?.hex || ''
	const isHighlighted = data?.card?.isHighlighted;
	const shouldCoverTakeFullSpace = data?.card?.shouldCoverTakeFullSpace;
	const isActive = data?.active || ACTIVE_STATE.YES
	return {
		id,
		url,
		cover,
		header,
		supportHeader,
		description,
		day,
		month,
		backgroundColor,
		isHighlighted,
		shouldCoverTakeFullSpace,
		isActive
	}
}

export function formatToCardItem(item, debug=false){
	let urlFormat = getUrlFunctionFromItem(item, debug)
	return ({
		...item,
		url: urlFormat(item)
	})
}

const Cards = ({ data: passedData =[], type=CARDS_TYPE.GRID, animation=false, paginate = false, isAllNewsPage= false, horizontalCards =false, ...props }) => {
	const data = passedData || []
	switch (type){
		case CARDS_TYPE.GRID:
			return <GridCards isAllNewsPage={isAllNewsPage} data={data} animation={animation} paginate={paginate} horizontalCards={horizontalCards} {...props}/>
		case CARDS_TYPE.LIST:
			return <ListCards data={data} animation={animation} paginate={paginate} {...props}/>
		default:
			return null
	}
};

export default Cards;
